class Configuration {

    // Cookies config
    COOKIE_NAME_TOKEN = "gelivery-token";
    COOKIE_NAME_LOGIN_USER_NAME = "gelivery-current-login";
    COOKIE_NAME_ROW_PER_PAGE = "rowPerPage";
    COOKIE_NAME_LANGUAGE = "gelivery-Language";
    DEFAULT_ROW_PER_PAGE = 10;
    COOKIE_NAME_ADMIN_DASHBOARD_HEADCOUNT_OPTION = "gelivery-adminDashBoardHeadCountOpt";
    COOKIE_NAME_AUTH = "geliveryauth";
    COOKIE_NAME_USER_DATA = "gelivery_usr_curr";
    COOKIE_NAME_DASHBOARD_DATE_RANGE = "gelivery-db-dr";
    COOKIE_NAME_JOINED_BRANCHES = "gelivery-jd-bches";
    COOKIE_NAME_LAST_ORDER_INDEX = "lst-odr-idx";
    MODULE_WAREHOUSE_SERVICE = true;
    MODULE_WAREHOUSE_SERVICE_SUGGEST_ROUTE = true;
    MODULE_WAREHOUSE_SERVICE_SHOW_SCHEDULE = false;
    COOKIE_NAME_SWITCH_BRANCH_GUID = "gelivery-switch-bch-gu";
    COOKIE_NAME_SWITCH_BRANCH_NAME = "gelivery-switch-bch-nm";
    MODULE_PICKUP_COLORFUL = true;
    ////////////////////////////////////////////////////////////////////////////
    
    // messages / errors
    MESSAGE_REQUEST_TIMEOUT = 'Request timeout';
    MESSAGE_AUTH_FAILED = 'Authentication failed';
    ////////////////////////////////////////////////////////////////////////////
    SERVICE_NAME_USER = '/v3/user';
    SERVICE_ACTION_USER_LOGIN= '/';
    SERVICE_ACTION_USER_BRIEF = '/brief';
    SERVICE_ACTION_USER_ROLE_CHECK ="/role_check";
    SERVICE_ACTION_USER_CHANGE_STATUS ="/change_status";
    SERVICE_ACTION_USER_CHANGE_PASSWORD ="/change_password";
    SERVICE_ACTION_USER_CHECK_AVAILABLE ="/available/";
    SERVICE_ACTION_USER_HR_AUTHORIZATION ="/hrm_authorization";


    SERVICE_NAME_DELIVERY = '/v3/delivery';
    SERVICE_ACTION_DELIVERY_COUNT_BY_STATUS = '/count_by_status';
    SERVICE_ACTION_DELIVERY_DAILY_COUNT = '/daily_count';
    SERVICE_ACTION_DELIVERY_OVERDUE_COUNT = '/overdue_count';
    SERVICE_ACTION_DELIVERY_OVERDUE_BY_DELIVERYMAN_GROUP = '/overdue_group_by_deliveryman';
    SERVICE_ACTION_DELIVERY_OVERDUE_BY_MERCHANT_GROUP = '/overdue_group_by_merchant';
    SERVICE_ACTION_DELIVERY_DAILY_CREATE = '/create';
    SERVICE_ACTION_DELIVERY_ACTIVE_ASSIGN_COUNT = '/active_assign_count';
    SERVICE_ACTION_DELIVERY_ACTIVE_MERCHANT_COUNT = '/active_merchant_count';
    SERVICE_ACTION_DELIVERY_LIST = '/list';
    SERVICE_ACTION_DELIVERY_DETAIL = '/detail/';
    SERVICE_ACTION_DELIVERY_AVAILABLE_EMPLOYEES = '/available_employees/';
    SERVICE_ACTION_DELIVERY_ASSIGN = '/assign';
    SERVICE_ACTION_DELIVERY_ACCEPT = '/accept';
    SERVICE_ACTION_DELIVERY_REJECT = '/reject';
    SERVICE_ACTION_DELIVERY_CANCEL = '/cancel';
    SERVICE_ACTION_DELIVERY_TRANSFER = '/transfer';
    SERVICE_ACTION_DELIVERY_RETRY = '/retry';
    SERVICE_ACTION_DELIVERY_PICKUP_COMPLETED = '/pickup_completed';
    SERVICE_ACTION_DELIVERY_RETURN = '/return';
    SERVICE_ACTION_DELIVERY_RETURNED = '/returned';
    SERVICE_ACTION_DELIVERY_COMPLETED = '/completed';
    SERVICE_ACTION_DELIVERY_CONTACT_UPDATE = '/update_contact';
    SERVICE_ACTION_DELIVERY_AVAILABLE_ALL_EMPLOYEES = '/available_all_employees';
    SERVICE_ACTION_DELIVERY_INVOICE_LIST = '/invoice_list';
    SERVICE_ACTION_DELIVERY_DOWNLOAD_INVOICE_LIST = '/download_invoice_list';
    SERVICE_ACTION_DELIVERY_DOWNLOAD_ACCOUNT_EMPLOYEE = '/download_account_employee';
    SERVICE_ACTION_DELIVERY_DOWNLOAD_ACCOUNT_OS = '/download_account_os';
    SERVICE_ACTION_DELIVERY_DOWNLOAD_ACCOUNT_ZONE = '/download_account_zone';
    SERVICE_ACTION_DELIVERY_FORCE_ACTION = '/force_action';
    SERVICE_ACTION_DELIVERY_AVAILABLE_DATES = '/available_delivery_dates';

    SERVICE_NAME_ADDRESS_BOOK = '/v3/address_book';
    SERVICE_ACTION_ADDRESS_BOOK_LIST = '/list';
    SERVICE_ACTION_ADDRESS_BOOK_CREATE = '/create';
    SERVICE_ACTION_ADDRESS_BOOK_UPDATE = '/update';
    SERVICE_ACTION_ADDRESS_BOOK_DELETE = '/delete/';
    SERVICE_ACTION_ADDRESS_BOOK_DETAIL = '/detail/';
    SERVICE_ACTION_ADDRESS_BOOK_LIST_PICKUP_POI = '/list_pickup_poi';
    SERVICE_ACTION_ADDRESS_BOOK_LIST_DELIVER_POI = '/list_delivery_poi';

    SERVICE_NAME_STATE = '/v3/state';
    SERVICE_ACTION_STATE_LIST = '/list';

    SERVICE_NAME_TOWNSHIP = '/v3/township';
    SERVICE_ACTION_TOWNSHIP_LIST = '/list';
    SERVICE_ACTION_TOWNSHIP_LIST_FOR_DELIVERY = '/list_for_delivery';
    SERVICE_ACTION_TOWNSHIP_LIST_FOR_PICKUP = '/list_for_pickup';
    SERVICE_ACTION_TOWNSHIP_LIST_FOR_ADMIN = '/list_for_admin';
    SERVICE_ACTION_TOWNSHIP_LIST_WITH_DISTANCE_BY_ZONE = '/list_with_distance_by_zone/';
    SERVICE_ACTION_TOWNSHIP_CREATE = '/create';
    SERVICE_ACTION_TOWNSHIP_UPDATE = '/update';
    SERVICE_ACTION_TOWNSHIP_DETAIL = '/detail/';

    SERVICE_NAME_STREET = '/v3/street';
    SERVICE_ACTION_STREET_LIST = '/list';

    SERVICE_NAME_IMAGE = '/v3/image';
    SERVICE_ACTION_IMAGE_DOWNLOAD = '/download/';
    SERVICE_ACTION_IMAGE_QR_CODE = '/qr_code/';
    SERVICE_ACTION_IMAGE_BAR_CODE = '/bar_code/';

    SERVICE_NAME_ZONE = '/v3/zone';
    SERVICE_ACTION_ZONE_LIST = '/list/';
    SERVICE_ACTION_ZONE_SEARCH = '/list_all/';
    SERVICE_ACTION_ZONE_CREATE = '/create';
    SERVICE_ACTION_ZONE_UPDATE = '/update';
    SERVICE_ACTION_ZONE_DELETE = '/delete/';
    SERVICE_ACTION_ZONE_CHANGE_STATUS = '/change_status/';
    SERVICE_ACTION_ZONE_DETAIL = '/detail/';

    SERVICE_NAME_ZONE_EMPLOYEE = '/v3/zone_assign';
    SERVICE_ACTION_ZONE_EMPLOYEE_LIST = '/list';
    SERVICE_ACTION_ZONE_EMPLOYEE_CREATE = '/create';
    SERVICE_ACTION_ZONE_EMPLOYEE_DELETE = '/delete/';

    SERVICE_NAME_ZONE_TOWNSHIP = '/v3/zone_township';
    SERVICE_ACTION_ZONE_TOWNSHIP_LIST = '/list';
    SERVICE_ACTION_ZONE_TOWNSHIP_CREATE = '/create';
    SERVICE_ACTION_ZONE_TOWNSHIP_DELETE = '/delete/';

    SERVICE_NAME_PRICING = '/v3/price';
    SERVICE_ACTION_PRICING_CALCULATE_PRICE = '/calculate_price/';
    SERVICE_ACTION_PRICING_CREATE = '/create';
    SERVICE_ACTION_PRICING_UPDATE = '/update';
    SERVICE_ACTION_PRICING_DELETE = '/delete/';
    SERVICE_ACTION_PRICING_LIST = '/list';
    SERVICE_ACTION_PRICING_DETAIL = '/detail/';
    SERVICE_ACTION_PRICING_LIST_CONTENT= '/list_contents/';
    SERVICE_ACTION_PRICING_LOCATION_ADD_REMOVE = '/add_remove';
    SERVICE_ACTION_PRICING_CREATE_V2 = '/create_v2';
    SERVICE_ACTION_PRICING_UPDATE_V2 = '/update_v2';
    SERVICE_ACTION_PRICING_ADD_EXTRA_UNIT_POLICY = '/add_extra_unit_policy';
    SERVICE_ACTION_PRICING_DELETE_EXTRA_UNIT_POLICY = '/delete_extra_unit_policy/';
    SERVICE_ACTION_PRICING_LIST_EXTRA_UNIT_POLICY = '/list_extra_unit_policy/';

    SERVICE_NAME_HOLIDAY = '/v3/holiday';
    SERVICE_ACTION_HOLIDAY_CREATE = '/create';
    SERVICE_ACTION_HOLIDAY_UPDATE = '/update';
    SERVICE_ACTION_HOLIDAY_DELETE = '/delete/';
    SERVICE_ACTION_HOLIDAY_LIST = '/list';

    SERVICE_NAME_PERSON = '/v3/person';
    SERVICE_ACTION_PERSON_ACTIVE_LIST_FOR_DELIVERY = '/active_list';
    SERVICE_ACTION_PERSON_ACTIVE_LIST_BY_ROLE_MIN = '/active_person_role_min_list';
    SERVICE_ACTION_PERSON_OS_BALANCE = '/os_balance';
    SERVICE_ACTION_PERSON_DOWNLOAD_OS_BALANCE = '/download_os_balance';
    SERVICE_ACTION_PERSON_LIST_BY_ROLE = '/list_by_role';
    SERVICE_ACTION_PERSON_CREATE = '/create';
    SERVICE_ACTION_PERSON_CREATE_BY_ADMIN = '/create_by_admin';
    SERVICE_ACTION_PERSON_LIST_MERCHANT = '/merchant_list';
    SERVICE_ACTION_PERSON_LIST_DELIVERYMAN = '/deliveryman_list';
    SERVICE_ACTION_PERSON_UPDATE = '/update';
    SERVICE_ACTION_PERSON_PROFILE = '/profile';
    SERVICE_ACTION_PERSON_PROFILE_UPDATE = '/update_profile';


    SERVICE_NAME_DEPOSIT = '/v3/deposit';
    SERVICE_ACTION_DEPOSIT_CREATE = '/create';
    SERVICE_ACTION_DEPOSIT_LIST = '/list';
    SERVICE_ACTION_DEPOSIT_DOWNLOAD = '/download_list';
    SERVICE_ACTION_DEPOSIT_RECEIVED_FROM_MERCHANT = '/received_from_merchant';

    SERVICE_NAME_EXPENSE = '/v3/expense';
    SERVICE_ACTION_EXPENSE_CREATE = '/create';
    SERVICE_ACTION_EXPENSE_LIST = '/list';
    SERVICE_ACTION_EXPENSE_DOWNLOAD = '/download_list';
    SERVICE_ACTION_EXPENSE_REFUND_TO_MERCHANT = '/refund_to_merchant';

    SERVICE_NAME_EMPLOYEE_COLLECTED = '/v3/employee_collected';
    SERVICE_ACTION_EMPLOYEE_COLLECTED_CREATE = '/clear';
    SERVICE_ACTION_EMPLOYEE_COLLECTED_LIST = '/list';
    SERVICE_ACTION_EMPLOYEE_COLLECTED_DOWNLOAD = '/download_list';
    SERVICE_ACTION_EMPLOYEE_COLLECTED_COD_SUMMARY = '/cash_on_delivery';

    SERVICE_NAME_PROMOTION = '/v3/promotion';
    SERVICE_ACTION_PROMOTION_CREATE = '/create';
    SERVICE_ACTION_PROMOTION_UPDATE = '/update';
    SERVICE_ACTION_PROMOTION_LIST = '/list';
    SERVICE_ACTION_PROMOTION_LIST_CODE = '/list/';
    SERVICE_ACTION_PROMOTION_LIST_CODE = '/download_code_by_promotion';
    SERVICE_ACTION_PROMOTION_CHECK_PROMO_CODE = '/check_promo_code/';

    SERVICE_NAME_DEPARTMENT = '/v3/department';
    SERVICE_ACTION_LIST_SEARCH = '/search';
    SERVICE_ACTION_CREATE = '/create';
    SERVICE_ACTION_UPDATE = '/update';
    SERVICE_ACTION_RETRIEVE = '/retrieve';
    SERVICE_ACTION_DELETE = '/delete';
    SERVICE_ACTION_LIST = '/list';
    SERVICE_ACTION_REPORT = '/report';
    SERVICE_ACTION_REPORT_LIST = '/report_list';
    SERVICE_ACTION_DEPARTMENT_LIST_VISIBLE = '/list_visible';
    SERVICE_ACTION_DEPARTMENT_LIST_JOINED = '/list_joined';

    SERVICE_NAME_CONTRACT_TYPE = '/v3/contract_type';
    SERVICE_NAME_CONTRACT_COMPONENT = '/v3/contract_component';
    SERVICE_NAME_CONTRACT_OS = '/v3/contract_os';

    ///////// V2
    SERVICE_NAME_PICKUP = '/v3/pickup';
    SERVICE_NAME_V2_DELIVERY = '/v3/deliver';
    SERVICE_ACTION_COUNT_STATUS_BY_PICKUP = '/count_by_status_pickup/';
    SERVICE_ACTION_COUNT_ACTIVE_BY_TOWN = '/active_count_by_town';
    SERVICE_ACTION_DOWNLOAD_PICKUP_INVOICE_LIST = '/download_pickup_invoice_list';
    SERVICE_ACTION_DOWNLOAD_INVOICE_V2 = '/download_invoice_list/';
    SERVICE_NAME_REPORTING = '/v3/reporting';
    SERVICE_ACTION_REPORTING_WAY_REPORT = '/way_report';
    SERVICE_ACTION_REPORTING_WAY_REPORT_DOWNLOAD = '/way_report_download';
    SERVICE_ACTION_REPORTING_INCOME_REPORT = '/income_report';
    SERVICE_ACTION_REPORTING_EXPENSE_REPORT = '/expense_report';
    SERVICE_ACTION_LIST_MERCHANT_DIRECT = '/list_merchant_direct';
    SERVICE_ACTION_REPORTING_BALANCE_REPORT = '/profit_summary_report';
    SERVICE_ACTION_REPORTING_BALANCE_REPORT_SUMMARY_BRANCH_LIST = '/profit_summary_report_by_branch_list';
    SERVICE_ACTION_REPORTING_BALANCE_REPORT_SUMMARY_ZONE_LIST = '/profit_summary_report_by_zone_list/';
    SERVICE_ACTION_LIST_PICKUP_CASH_ADVANCE = '/cash_advance';

    SERVICE_NAME_DOWNLOAD_V2 = '/v3/download';
    SERVICE_ACTION_PICKUP_LIST_DOWNLOAD = '/pickup_list';
    SERVICE_ACTION_DELIVER_LIST_DOWNLOAD = '/deliver_list';
    SERVICE_ACTION_DELIVER_LIST_DOWNLOAD_MIN = '/deliver_list_min';
    SERVICE_ACTION_PICKUP_INVOICE_LIST_DOWNLOAD = '/pickup_invoice_list';
    SERVICE_ACTION_DELIVER_INVOICE_LIST_DOWNLOAD = '/deliver_invoice_list/';
    SERVICE_ACTION_DELIVER_INVOICE_PDF_DOWNLOAD = '/deliver_invoice_pdf/';
    SERVICE_ACTION_DELIVER_INVOICE_EXCEL_DOWNLOAD = '/deliver_invoice_excel/';
    SERVICE_ACTION_PAYMENT_RECEIVED_LIST_DOWNLOAD = '/payment_received_list';
    SERVICE_ACTION_PAYMENT_REFUND_LIST_DOWNLOAD = '/payment_refund_list';
    SERVICE_ACTION_DELIVERYMAN_CASH_COLLECTION_LIST_DOWNLOAD = '/deliveryman_collections_list';
    SERVICE_ACTION_WAY_REPORT_LIST_DOWNLOAD = '/way_report_list';
    SERVICE_ACTION_WAY_REPORT_COUNT_BY_TOWN_LIST_DOWNLOAD = '/way_by_town_list';
    SERVICE_ACTION_WAY_REPORT_OVERDUE_COUNT_DOWNLOAD = '/overdue_count_list';
    SERVICE_ACTION_WAY_REPORT_OVERDUE_COUNT_BY_DELIVERYMAN_DOWNLOAD = '/overdue_count_by_deliveryman_list';
    SERVICE_ACTION_WAY_REPORT_OVERDUE_COUNT_BY_MERCHANT_DOWNLOAD = '/overdue_count_by_merchant_list';
    SERVICE_ACTION_WAY_REPORT_INCOME_DOWNLOAD = '/income_report';
    SERVICE_ACTION_WAY_REPORT_EXPENSE_DOWNLOAD = '/expense_report';
    SERVICE_ACTION_REPORT_EXPENSE_LIST_DOWNLOAD = '/expense_list';
    SERVICE_ACTION_PICKUP_LIST_WITH_DELIVERY_WAYS_DOWNLOAD = '/pickup_list_with_delivery_ways';
    SERVICE_ACTION_CASH_ON_DELIVERY_DOWNLOAD = '/cash_on_delivery';

    SERVICE_NAME_COMMENT_V2 = '/v3/comment';
    
    SERVICE_NAME_CHAT = '/v3/chatsession';
    SERVICE_ACTION_CHAT_SEND_MESSAGE = '/sendmessage';
    SERVICE_ACTION_CHAT_USER_SEARCH = '/search';
    SERVICE_ACTION_CHAT_LAST_CHAT_LIST = '/latestlist';
    SERVICE_ACTION_CHAT_GROUP_LIST = '/grouprecord';
    SERVICE_ACTION_CHAT_INDIVIDUAL_LIST = '/individualrecord';
    SERVICE_ACTION_CHAT_SESSION_CREATE = '/create';
    SERVICE_ACTION_CHAT_ADD_MEMBER = '/addmember';

    SERVICE_NAME_FILE='/v3/files'
    SERVICE_ACTION_FILES_DOWNLOAD = '/download/';

    SERVICE_ACTION_PRICING_CREATE_EXCLUSIVE = '/create_exclusive';
    SERVICE_ACTION_PRICING_UPDATE_EXCLUSIVE = '/update_exclusive';
    SERVICE_ACTION_PRICING_DELETE_EXCLUSIVE = '/delete_exclusive/';
    SERVICE_ACTION_PRICING_LIST_EXCLUSIVE = '/list_exclusive';

    DEFAULT_ROW_PER_PAGE_GRID = 15;
    SERVICE_NAME_WAREHOUSE_STATION ='/v3/warehouse_station'
    SERVICE_ACTION_WAREHOUSE_STATION_DETAIL_BY_ZONE = '/detail_by_zone/';
    SERVICE_ACTION_WAREHOUSE_STATION_CREATE = '/create';
    SERVICE_ACTION_WAREHOUSE_STATION_UPDATE = '/update';
    SERVICE_ACTION_WAREHOUSE_STATION_LIST = '/list_all';
    SERVICE_ACTION_WAREHOUSE_STATION_DETAIL_BY_PICKUP = '/detail_by_pickup/';
    SERVICE_ACTION_WAREHOUSE_STATION_DETAIL = '/detail/';
    SERVICE_ACTION_WAREHOUSE_STATION_LIST_NETWORK = '/list_network';

    SERVICE_NAME_WAREHOUSE_BUCKET ='/v3/warehouse_bucket'
    SERVICE_ACTION_WAREHOUSE_BUCKET_LIST_BY_STATION = '/list_by_station/';
    SERVICE_ACTION_WAREHOUSE_BUCKET_CREATE = '/create';
    SERVICE_ACTION_WAREHOUSE_BUCKET_UPDATE = '/update';
    SERVICE_ACTION_WAREHOUSE_BUCKET_DELETE = '/delete/';
    SERVICE_ACTION_WAREHOUSE_BUCKET_LIST_BY_PICKUP = '/list_by_pickup/';

    SERVICE_NAME_AUDIT_LOGS='/v3/logs'
    SERVICE_ACTION_AUDIT_LOGS_LIST = '/list';

    SERVICE_NAME_WAREHOUSE_SCHEDULE ='/v3/warehouse_schedule'
    SERVICE_ACTION_WAREHOUSE_SCHEDULE_LIST_BY_STATION = '/list_by_station/';
    SERVICE_ACTION_WAREHOUSE_SCHEDULE_CREATE = '/create';
    SERVICE_ACTION_WAREHOUSE_SCHEDULE_UPDATE = '/update';
    SERVICE_ACTION_WAREHOUSE_SCHEDULE_DELETE = '/delete/';
    SERVICE_ACTION_WAREHOUSE_SCHEDULE_LIST_BY_ACTIVE = '/list_by_active';

    SERVICE_NAME_WAREHOUSE_ROUTE ='/v3/warehouse_route'
    SERVICE_ACTION_WAREHOUSE_ROUTE_LIST_BY_PICKUP_DELIVER = '/list_by_pickup_deliver/';
    SERVICE_ACTION_WAREHOUSE_ROUTE_LIST = '/list';
    SERVICE_ACTION_WAREHOUSE_ROUTE_DETAIL = '/detail/';
    SERVICE_ACTION_WAREHOUSE_ROUTE_ASSIGN = '/assign';
    SERVICE_ACTION_WAREHOUSE_ROUTE_REJECT = '/reject';
    SERVICE_ACTION_WAREHOUSE_ROUTE_CANCEL = '/cancel';
    SERVICE_ACTION_WAREHOUSE_ROUTE_TRANSFER = '/transfer';
    SERVICE_ACTION_WAREHOUSE_ROUTE_COMPLETE = '/completed';
    SERVICE_ACTION_WAREHOUSE_ROUTE_BUCKET_REQUEST = '/bucket_request';
    SERVICE_ACTION_WAREHOUSE_ROUTE_BUCKET_ACCEPT = '/bucket_accept';
    SERVICE_ACTION_WAREHOUSE_AVAILABLE_EMPLOYEES = '/available_employees/';
    SERVICE_ACTION_WAREHOUSE_ROUTE_SUGGEST_ROUTES = '/suggest_routes/';
    SERVICE_ACTION_WAREHOUSE_ROUTE_STATION_DISTANCE = '/station_distance/';
    SERVICE_ACTION_WAREHOUSE_ROUTE_INBOUND_COUNT_BY_STATUS = '/inbound_count_by_status';
    SERVICE_ACTION_WAREHOUSE_ROUTE_OUTBOUND_COUNT_BY_STATUS = '/outbound_count_by_status';
    SERVICE_ACTION_WAREHOUSE_ROUTE_COUNT_BY_STATUS_AND_EMPLOYEE = '/count_by_status_and_employee';
    SERVICE_ACTION_WAREHOUSE_ROUTE_COUNT_BY_DELIVERY_TRACKING_ID = '/routes_by_delivery_tracking_id/';

    SERVICE_NAME_ACCOUNT ='/v3/account'
    SERVICE_ACTION_ACCOUNT_LIST = '/list';
    SERVICE_ACTION_ACCOUNT_ACCOUNT_BALANCE_LIST = '/account_balance';
    SERVICE_ACTION_ACCOUNT_CREATE = '/create';
    SERVICE_ACTION_ACCOUNT_UPDATE = '/update';
    SERVICE_ACTION_ACCOUNT_DELETE = '/delete/';
    SERVICE_ACTION_ACCOUNT_ACCOUNT_GENERAL_JOURNAL_LIST = '/general_journal';
    SERVICE_ACTION_ACCOUNT_ACCOUNT_INCOME_STATEMENT_LIST = '/income_statement';
    SERVICE_ACTION_ACCOUNT_LIST_GROUP = '/list_group';
    SERVICE_ACTION_ACCOUNT_ACCOUNT_PROFIT_AND_LOSS = '/profit_loss';
    SERVICE_ACTION_ACCOUNT_ACCOUNT_BALANCE_SHEET_LIST = '/balance_sheet';
    SERVICE_ACTION_ACCOUNT_DAILY_ACCOUNTING_LIST = '/daily_account';
    SERVICE_ACTION_ACCOUNT_JOURNAL_SUMMARY_LIST = '/journal_summary';
    SERVICE_ACTION_ACCOUNT_TRIAL_BALANCE_LIST = '/trial_balance';

    SERVICE_NAME_LEDGER_ENTRY ='/v3/ledger_entry'
    SERVICE_ACTION_LEDGER_ENTRY_SIMPLE_ENTRY = '/simple_entry';
    SERVICE_ACTION_LEDGER_ENTRY_ENTRY = '/entry';
    SERVICE_ACTION_LEDGER_ENTRY_LIST = '/general_ledger';

    SERVICE_ACTION_PERSON_SYNC_TO_HRM = '/sync_to_hrm';

    SERVICE_NAME_ACCOUNTING_DOWNLOAD ='/v3/accounting_download'
    SERVICE_ACTION_ACCOUNTING_DOWNLOAD_INCOME_STATEMENT ='/income_statement'
    SERVICE_ACTION_ACCOUNTING_DOWNLOAD_PROFIT_AND_LOSS = '/profit_loss';
    SERVICE_ACTION_ACCOUNTING_DOWNLOAD_BALANCE_SHEET_LIST = '/balance_sheet';
    SERVICE_ACTION_ACCOUNTING_DOWNLOAD_GENERAL_JOURNAL_LIST = '/general_journal';
    SERVICE_ACTION_ACCOUNTING_DOWNLOAD_GENERAL_LEDGER_LIST = '/general_ledger';
    SERVICE_ACTION_ACCOUNTING_DOWNLOAD_DAILY_ACCOUNTING_LIST = '/daily_account';
    SERVICE_ACTION_ACCOUNTING_DOWNLOAD_JOURNAL_SUMMARY_LIST = '/journal_summary';
    SERVICE_ACTION_ACCOUNTING_DOWNLOAD_TRIAL_BALANCE_LIST = '/trial_balance';

    SERVICE_ACTION_ADDRESS_BOOK_LIST_DOWNLOAD = '/address_book_list';
    SERVICE_NAME_ROLE ='/v3/authority';
    SERVICE_ACTION_ROLE_LIST ='/list';
    SERVICE_ACTION_PRIVILEGES_LIST_BY_ROLE ='/privileges_by_role/';
    SERVICE_ACTION_ROLE_CREATE ='/create';
    SERVICE_ACTION_ROLE_UPDATE ='/update';
    SERVICE_ACTION_ROLE_CHANGE_STATUS ='/status/';
    SERVICE_ACTION_ROLE_MIN_LIST ='/list_min';

    SERVICE_NAME_SETTINGS ='/v3/settings';
    SERVICE_ACTION_SETTINGS_LOCAL_BY_NAME ='/local_setting/';
    SERVICE_ACTION_SETTINGS_GLOBAL_BY_NAME ='/global_setting/';
    SERVICE_ACTION_SETTINGS_GLOBAL_LIST ='/setting_global_list';
    SERVICE_ACTION_SETTINGS_GLOBAL_UPDATE ='/setting_global_update';
    SERVICE_ACTION_SETTINGS_LOCAL_UPDATE ='/update';

    SERVICE_ACTION_DELIVERY_DELETE = '/remove';
    SERVICE_ACTION_DELIVER_DOWNLOAD_STORE_LIST = '/store_list';
    SERVICE_ACTION_DELIVER_UPLOAD_WAYS = '/upload_ways/';
    SERVICE_ACTION_DELIVER_CREATE_UPLOAD_WAYS = '/create_upload_ways/';



    //Product
    SERVICE_NAME_PRODUCT = '/v1/posproduct';
    SERVICE_ACTION_PRODUCT_ADD = '/add';
    SERVICE_ACTION_ALL_CATEGORY = '/allcategory';
    SERVICE_ACTION_PRODUCT_GET_PRODUCT   = '/getproduct';
    SERVICE_ACTION_PRODUCT_ADD_CATIMAGE   = '/addcatimageproduct';
    SERVICE_ACTION_PRODUCT_LIST   = '/productlist';

    //Purchase
    SERVICE_NAME_PURCHASE = '/v3/pospurchase';
    SERVICE_ACTION_PURCHASE_SUPPLIER_LIST = '/supplierlist';
    SERVICE_ACTION_PURCHASE_CREATE = '/create';
    SERVICE_ACTION_PURCHASE_LIST = '/list';
    SERVICE_ACTION_DAILY_PURCHASE_LIST = '/dailylist';

    SERVICE_ACTION_POS_DAILY_PURCHASE_ITEM_LIST = '/purchasestocklist';

    SERVICE_ACTION_POS_ALL_PURCHASE_ITEM_LIST = '/purchaseallstocklist';
    //category
    SERVICE_NAME_CATEGORY = '/v1/poscategory';
    SERVICE_ACTION_CATEGORY_LIST = '/list';
    SERVICE_ACTION_CATEGORY_CREATE = '/create';
    SERVICE_ACTION_CATEGORY_GET = '/get';
    //supplier
    SERVICE_NAME_SUPPLIER = '/v3/possupplier';
    SERVICE_ACTION_SUPPLIER_LIST = '/list';
    SERVICE_ACTION_SUPPLIER_CREATE = '/create';
    SERVICE_ACTION_SUPPLIER_GET = '/get';
    //Purchase
    SERVICE_NAME_POS_ORDER = '/v3/posorder';
    SERVICE_ACTION_POS_ORDER_CREATE = '/create';
    SERVICE_ACTION_POS_ORDER_CANCEL = '/cancel';
    SERVICE_ACTION_POS_ORDER_LIST = '/list';
    SERVICE_ACTION_POS_DAILYORDER_LIST = '/dailylist';
    SERVICE_ACTION_POS_SINGLEORDER_LIST = '/singleorder';
    SERVICE_ACTION_POS_REPORT_ORDER_STOCK_LIST = '/sale_order_by_product';
    SERVICE_ACTION_POS_ORDER_PROFIT_SUMMARY = '/purchase_sales_summary';

     //StockPrice
     SERVICE_NAME_POS_STOCKPRICE = '/v1/posstockpricelog';
     SERVICE_ACTION_POS_STOCKPRICE_LIST = '/list';
    //StockQty
     SERVICE_NAME_POS_STOCKQTY = '/v1/posstockqtylog';
    SERVICE_ACTION_POS_STOCKQTY_LIST = '/list';

    COOKIE_NAME_POS_PRIVILEGE = "pos_privilege";
    SERVICE_ACTION_ORDER_LIST_DOWNLOAD = '/order_list/';
    SERVICE_ACTION_FILES_POS_DOWNLOAD_V2 = '/v2/posdownload';


    SERVICE_NAME_BANK='/v1/bank'
    SERVICE_ACTION_BANK_LIST_BY_PEOPLE = '/list_by_people';
    SERVICE_ACTION_BANK_LIST = '/list_banks';
    SERVICE_ACTION_BANK_CREATE = '/create';
    SERVICE_ACTION_BANK_UPDATE = '/update';

    SERVICE_NAME_MERCHANT_BUSINESS='/v3/merchant_business'
    SERVICE_ACTION_MERCHANT_BUSINESS_LIST_BUSINESS_PERSON = '/list_business_person';
    SERVICE_ACTION_MERCHANT_BUSINESS_LIST = '/list_business';
    SERVICE_ACTION_MERCHANT_BUSINESS_CREATE = '/add/';
    SERVICE_ACTION_MERCHANT_BUSINESS_REMOVE = '/remove';

    SERVICE_ACTION_POS_ORDER_DETAIL = '/detail/';
    SERVICE_ACTION_POS_ORDER_PAYMENT_RECEIVED = '/payment_receive';
    SERVICE_ACTION_POS_ORDER_CANCEL = '/cancel/';
    SERVICE_ACTION_POS_ORDER_CONFIRM_PAYMENT = '/confirm_payment/';
    SERVICE_ACTION_POS_ORDER_EDIT_PRODUCT = '/edit_product/';
    SERVICE_ACTION_WAREHOUSE_ROUTE_SUGGEST_RETURN_ROUTES = '/suggest_return_routes/';
    SERVICE_ACTION_WAREHOUSE_ROUTE_SAVE_RETURN_ROUTES = '/save_return_route';
    SERVICE_ACTION_POS_ORDER_REMOVE_PRODUCT = '/remove_product/';
    SERVICE_ACTION_POS_PURCHASE_CHANGE_STATUS = '/change_status/';
    SERVICE_ACTION_ACCOUNTING_DOWNLOAD_ACCOUNT_TITLE_LIST = '/account_list';

    SERVICE_NAME_CURRENCY_RATE='/v3/currency'
    SERVICE_ACTION_CURRENCY_RATE_LIST = '/list';
    SERVICE_ACTION_CURRENCY_RATE_LIST_RATE = '/rate';
    SERVICE_ACTION_CURRENCY_RATE_CREATE = '/add/';

    SERVICE_ACTION_POS_PURCHASE_CHANGE_SUMMARY_DETAIL = '/detail_summary/';
    SERVICE_ACTION_SUPPLIER_ACTIVE_LIST = '/active_suppliers';
    SERVICE_ACTION_POS_PURCHASE_DAMAGE_LIST = '/damage_list';
    SERVICE_ACTION_SALES_ORDER_LIST_DOWNLOAD = '/posorder_list';

    SERVICE_NAME_FINANCIAL_CENTER='/v3/financial_center'
    SERVICE_ACTION_FINANCIAL_CENTER_LIST = '/list';
    SERVICE_ACTION_FINANCIAL_CENTER_MERCHANT_LIST = '/list_merchant';
    SERVICE_ACTION_FINANCIAL_CENTER_INVOICING = '/invoicing';
    SERVICE_ACTION_FINANCIAL_CENTER_LIST_INVOICES = '/list_invoice';
    SERVICE_ACTION_FINANCIAL_CENTER_GET_INVOICE ='/invoice/';
    SERVICE_ACTION_FINANCIAL_CENTER_DOWNLOAD_INVOICE_PDF = '/invoice_pdf/';
    SERVICE_ACTION_FINANCIAL_CENTER_DOWNLOAD_INVOICE_EXCEL = '/invoice_excel/';
    SERVICE_ACTION_FINANCIAL_CENTER_INVOICING_SAVING = '/invoicing_save';

    SERVICE_ACTION_AUDIT_LOGS_LIST_FOR_PRICE_CHANGE = '/list_for_price/';
    SERVICE_ACTION_ACCOUNTING_INCOME_STATEMENT_BY_DATE_RANGE = '/income_statement_by_date_range';
    SERVICE_ACTION_POS_SALES_DAILY_SALES_BY_CATEGORY = '/daily_sales_by_category';
    SERVICE_ACTION_CUSTOMER_DEPOSIT_BALANCE = '/customer_deposit_balance/';
    SERVICE_ACTION_SALE_ORDER_BY_SELLER_BUSINESS = '/sale_order_by_seller_business';
    SERVICE_ACTION_CLEAR_MULTIPLE_WAYS = '/clear_multiple_ways';
    SERVICE_ACTION_WAREHOUSE_BUCKET_ROUTE_LIST_BY = '/fetch_bucket/';
    SERVICE_ACTION_POS_CUSTOMER_LIST = '/pos_customer_list';
    SERVICE_ACTION_CUSTOMER_DEPOSIT_LEDGER_ENTRY_LIST = '/customer_deposit_list';
    SERVICE_ACTION_LEDGER_ENTRY_CUSTOMER_DEPOSIT_ENTRY = '/customer_deposit';
    SERVICE_ACTION_DELIVER_LIST_DOWNLOAD_NINJAVAN = '/way_list_ninjavan';
    SERVICE_ACTION_POS_ORDER_UPDATE = '/update';
    SERVICE_ACTION_PERSON_CUSTOMER_BY_TOWN = '/customer_by_town';
    SERVICE_ACTION_SALE_ORDER_BY_SELLER_AND_STATUS = '/order_by_seller';
    SERVICE_ACTION_SALE_ORDER_BY_PAGE_AND_STATUS = '/order_by_page';
    SERVICE_ACTION_SALE_ORDER_GROUP_BY_PRODUCT_AND_STATUS = '/order_group_by_product';
    SERVICE_ACTION_WAY_COUNT_FOR_POS_BY_DELIVERYMAN = '/way_count_by_deliveryman';
    SERVICE_ACTION_DELIVERY_WAY_RATIO = '/delivery_way_ratio';
    SERVICE_ACTION_DELIVERY_SHIPMENT_RATIO = '/delivery_shipment_ratio';
    SERVICE_ACTION_SALE_ORDER_STATUS_RATIO = '/sale_order_status_ratio';
    SERVICE_ACTION_MERCHANT_BUSINESS_LIST_BUSINESS_PERSON_FOR_ORDER = '/list_business_person_for_order';
    SERVICE_ACTION_FINANCIAL_CENTER_BRANCH_CASH_ON_DELIVERY = '/branch_cash_on_delivery';
    SERVICE_ACTION_LEDGER_ENTRY_BRANCH_COD_TRANSFER_ENTRY = '/branch_cod_transfer';
    SERVICE_ACTION_BRANCH_TRANSFER_LEDGER_ENTRY_LIST = '/branch_transfer_list';
    SERVICE_ACTION_ADDRESS_BOOK_LIST_DELIVER_POI_FOR_DIRECT_ORDER = '/list_delivery_poi_for_direct_order';
    SERVICE_ACTION_PICKUP_DIRECT_ORDER = '/create_direct_order';
    SERVICE_ACTION_WINNING_CUSTOMER_LIST = '/winning_prize_customer_list';
    SERVICE_ACTION_LEDGER_ENTRY_WINNER_ENTRY = '/winning_prize_entry';
    SERVICE_ACTION_CUSTOMER_WINNING_LEDGER_ENTRY_LIST = '/customer_winner_list';
    SERVICE_ACTION_CUSTOMER_WINNING_LEDGER_ENTRY_REMOVE = '/remove_transaction/';
    SERVICE_ACTION_SALE_ORDER_DEPOSIT_LEDGER_ENTRY_LIST = '/deposit_by_sale_list';
    SERVICE_ACTION_CUSTOMER_PRIZE_BALANCE = '/customer_prize_balance/';
    SERVICE_ACTION_POS_ORDER_PAYMENT_WITH_DEPOSIT = '/payment_with_deposit/';
    SERVICE_ACTION_POS_ORDER_PAYMENT_WITH_PRIZE = '/payment_with_winning_prize/';
    SERVICE_ACTION_FINANCIAL_CENTER_DOWNLOAD_WAY_LIST = '/finance_ways_list';
    SERVICE_ACTION_PURCHASE_DETAIL_ITEM_LIST = '/get_purchase_detail_item_list_by_guid/';
    SERVICE_ACTION_PURCHASE_DETAIL_ITEM_ADD = '/add_purchase_detail_item';
    SERVICE_ACTION_DELIVER_CREATE_WAYS_BY_BATCH = '/create_ways_by_batch/';
    SERVICE_ACTION_DELIVER_INVOICE_EXCEL_DOWNLOAD_FOR_PREVIEW = '/preview_invoice_excel';
    SERVICE_ACTION_ACCOUNT_ACCOUNT_CASH_VOUCHER_LIST = '/cash_voucher_list';
    SERVICE_ACTION_ACCOUNT_ACCOUNT_CASH_VOUCHER_LIST_DOWNLOAD = '/cash_voucher_list';
    SERVICE_ACTION_DELIVERY_MULTIPLE_ACTION = '/multiple_action';
    SERVICE_ACTION_TOWNSHIP_TOTAL_WAYS_BY_TOWN = '/total_ways_by_town';
    SERVICE_ACTION_FINANCIAL_CENTER_TRANSPORTATION_COST_BY_DELIVERYMAN = '/transportation_cost/';
    SERVICE_ACTION_POS_ORDER_PRINT_COUNT_INCREASE = '/print_count_increase/';
    SERVICE_ACTION_PERSON_LIST_MERCHANT_COMPARE_BY_MONTH = '/merchant_compare_by_month';
    SERVICE_ACTION_FINANCIAL_CENTER_DOWNLOAD_INVOICE_HEADING = '/invoice_heading/';
    SERVICE_ACTION_DELIVER_INVOICE_EXCEL_DOWNLOAD_FOR_PREVIEW_PDF = '/preview_invoice_pdf';

    SERVICE_ACTION_FINANCIAL_CENTER_WAY_LIST_BY_BRANCH = '/list_for_branch';
    SERVICE_ACTION_FINANCIAL_CENTER_LIST_MERCHANT_PREPAID = '/list_merchant_prepaid';
    SERVICE_ACTION_FINANCIAL_CENTER_CLEAR_MERCHANT_PREPAID = '/clear_merchant_prepaid';
}
export default Configuration;